import {useAtom} from 'jotai'
import {useUpdateAtom} from 'jotai/utils'

import {isErrorAtom, stepperAtom, userDataAtom} from 'atoms'
import {Dialog} from "@headlessui/react";
import {BanIcon} from '@heroicons/react/solid'
import {useState, useEffect} from "react";
import API from '../api';

export default function VerifyClient() {

    const updateStepper = useUpdateAtom(stepperAtom);
    const [userData, setUserData] = useAtom(userDataAtom);
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [isError, setIsError] = useAtom(isErrorAtom);
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');

    function verifyClient() {
        if (email !== userData.email) {
            setAlertOpen(true)
        } else {
            updateStepper(2)
        }
    }

    useEffect(() => {
        const fetchUserData = async () => {
            const dataParam = new URLSearchParams(window.location.search).get('data');
            await API.get('/integration/decryptCrm/' + dataParam)
                .then(response => {
                    setUserData(response.data);
                    setLoading(false)
                })
                .catch(error => {
                    setIsError(true)
                    setLoading(false)
                });
        }
        fetchUserData()
    }, [setUserData, setLoading, setIsError])

    return (<div className="bg-white shadow sm:rounded-lg mt-4">
        <div className="px-4 py-5 sm:p-6">
            {!isError ? (
                <div>
                    <h3 className="text-lg leading-6 font-medium text-zinc-900">Overenie emailovej adresy</h3>
                    <div className="mt-2 text-sm text-gray-500">
                        <p>Vitajte v kalendári pre výber termínu odovzdania / vrátenia vozidla k nájomnej zmluve
                            č.<strong>{userData?.orderNumber}</strong>.</p>
                        <p>Pre pokračovanie do výberu zadajte emailovú adresu, na ktorú vám prišiel notifikačný
                            email.</p>
                    </div>
                    <div className="mt-5 sm:flex sm:items-center">
                        <div className="w-full sm:max-w-xs">
                            <label htmlFor="email" className="sr-only">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="vas@email.sk"/>
                        </div>
                        <button type="button"
                                className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => {
                                    verifyClient()
                                }}>
                            Ďalej
                        </button>
                    </div>
                </div>) : (
                <div className='flex flex-col items-center gap-3 my-auto mx-auto bg-white w-fit z-20 rounded-lg br text-center'>
                    <BanIcon className="h-12 w-12 text-primary-700" aria-hidden="true"/>
                    <p className='text-lg font-medium leading-6 text-zinc-900'>Chyba</p>
                    <p className='text-sm text-gray-500'>Skontrolujte, či je vaša URL adresa správna.</p>
                </div>)}
        </div>
        {loading && <div
            className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
            <svg className="animate-spin h-10 w-10 text-primary-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                <path className="opacity-100" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
            </svg>
            <h2 className="text-center text-white text-xl font-semibold">Načítavam Vaše údaje...</h2>
        </div>}
        <Dialog open={isAlertOpen} onClose={() => setAlertOpen(false)}
                className='fixed z-10 inset-0 flex items-center'>
            <Dialog.Overlay className='fixed inset-0 bg-black opacity-50'/>
            <div
                className='flex flex-col items-center gap-3 my-auto mx-auto bg-white p-6 w-fit z-20 rounded-lg br text-center'>
                <BanIcon className="h-12 w-12 text-primary-700" aria-hidden="true"/>
                <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-zinc-900'
                >
                    Overenie e-mailu zlyhalo
                </Dialog.Title>
                <p className='text-sm text-gray-500'>Uistite sa, že Vás email je správny.</p>
                <button type="button"
                        className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setAlertOpen(false)}>
                    Dobré
                </button>
            </div>
        </Dialog>
    </div>)
}